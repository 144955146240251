import React from 'react'

import { useGetAppPlanRows } from '../../../../hooks/api/queries/useGetAppPlanRows'

import Card from '../../../../components/Card'

import classNames from 'classnames'
import { PlanRow } from '../../../../types'

interface PlansProps {
  appId: string
  planName?: string
}

const Plan: React.FC<PlansProps> = ({ appId, planName }) => {
  const { data: data } = useGetAppPlanRows(appId)

  return (
    <>
      <Card title={planName ? planName : ''}>
        {data?.length !== 0 && (
          <>
            <hr />
            <table className="table-fixed w-full mb-12">
              <tbody>
                {data?.map((row: PlanRow, index: number) => {
                  return (
                    <tr key={row.id}>
                      <td
                        className={classNames(
                          'w-2/3 py-3  text-sm font-semibold  whitespace-pre-line',
                          { 'border-b': data?.length !== index + 1 }
                        )}
                      >
                        {`${row.description}`}
                      </td>
                      <td
                        className={classNames('w-1/3 py-3  text-sm', {
                          'border-b': data?.length !== index + 1,
                        })}
                      >
                        {row.price}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
        {data?.length === 0 && (
          <p className="text-xl">No plan to show for this app...</p>
        )}
      </Card>
    </>
  )
}

export default Plan
