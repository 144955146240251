import { QueryClient, QueryClientProvider } from 'react-query'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AxiosConfig from './Axios'

import Login from './pages/auth/Login'
import LoggedApp from './pages/LoggedApp'

import { ToastContainer } from 'react-toastify'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <div className="app text-gray-900 antialiased">
        <BrowserRouter>
          <AxiosConfig />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/*" element={<LoggedApp />} />
          </Routes>
          <ToastContainer limit={1} />
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}

export default App
