import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import axios from 'axios'
import { toast } from 'react-toastify'

import { API_URL } from './config'

const configureAxios = (navigate: (path: string) => void, location: any) => {
  // AXIOS GLOBAL CONFIG
  axios.defaults.baseURL = API_URL

  // REQUEST INTERCEPTOR TO SET COOKIE AUTHENTICATION
  axios.interceptors.request.use(
    function (config) {
      config.withCredentials = true

      return config
    },

    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // RESPONSE INTERCEPTOR TO CATCH 401
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response?.status === 500) {
        toast.error(`An error occurred while communicating with the server.`, {
          autoClose: 5000,
          position: 'bottom-right',
        })

        if (error.response.config.url.includes('customer')) {
          navigate('/customers')
        }

        return error
      }

      if (error?.response?.status === 400) {
        toast.error(`Bad request.`, {
          autoClose: 5000,
          position: 'bottom-right',
        })

        return error
      }

      if (error?.response?.status === 409) {
        if (typeof error?.response?.data?.message === 'string') {
          toast.error(`${error?.response?.data?.message}`, {
            autoClose: 5000,
            position: 'bottom-right',
          })
          if (error.response.data.message === "This isn't a valid customer") {
            navigate('/customers')
          }
        } else {
          toast.error(`${error?.response?.data?.message[0]}`, {
            autoClose: 5000,
            position: 'bottom-right',
          })
        }
        return error
      }

      if (error?.response?.status === 401) {
        if (
          error?.response.config.url === '/session' &&
          error?.response.config.method === 'post'
        ) {
          // Login con credenziali errate
          toast.error('Wrong username or password', {
            position: 'bottom-right',
          })
          return error
        }

        return error
      }
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.message === 'JWT EXPIRED'
      ) {
        toast.warn('Session expired', {
          position: 'bottom-right',
        })
        navigate('/')
        return error
      }

      if (
        error?.response?.status === 403 &&
        error?.response?.data?.message === 'Not verified app'
      ) {
        toast.error(error?.response?.data?.message, {
          position: 'bottom-right',
          autoClose: 5000,
        })
        navigate('/apps')
      }
      return error
    }
  )
}

const AxiosConfig = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    configureAxios(navigate, location)
  }, [navigate, location])

  return null
}

export default AxiosConfig
