import axios from 'axios'

import { useQuery } from 'react-query'

const fetchSharedNotes = async (appId: string) => {
  const { data: result } = await axios.get<{ sharedNotes: string }>(
    `customers/app/${appId}/shared-notes`
  )

  return result
}

export const useGetCustomerAppSharedNotes = (appId: string) =>
  useQuery(['sharedNotes', appId], () => fetchSharedNotes(appId))
