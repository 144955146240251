import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

const usePatchCustomerAppSharedNotes = (appId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: { sharedNotes: string }) => {
      const { data: result } = await axios.patch<{ sharedNotes: string }>(
        `/customers/app/${appId}/shared-notes`,
        values
      )
      return result
    },
    {
      onSuccess: (data) => {
        if (typeof data.sharedNotes === 'string') {
          queryClient.setQueryData('sharedNotes', data)
          queryClient.invalidateQueries('sharedNotes')
          toast.success('Notes saved successfully', {
            position: 'bottom-right',
          })
        }
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchCustomerAppSharedNotes
