import { useMutation } from 'react-query'

import axios from 'axios'

const useDownloadContract = (partnerId: number) => {
  return useMutation(async () => {
    const response = await axios.get<Blob>(
      `partners/${partnerId}/downloadContract`,
      {
        responseType: 'blob',
      }
    )
    return response
  })
}

export default useDownloadContract
