import React, { ReactElement } from 'react'

import PartnerDetails from './PartnerDetails'
import PartnerDownloadContract from './PartnerContractDownload'
import DeleteAccount from './DeleteAccount'

import AsideMenu from '../../../components/AsideMenu'

import { useSession } from '../../../hooks/api/queries/useSessions'

interface PartnerRoute {
  path: string
  label: string
  element: ReactElement
}

const PartnerPage: React.FC = () => {
  const { data: partner, isLoading } = useSession()

  if (!partner) {
    return null
  }

  const USER_ROUTES: PartnerRoute[] = [
    {
      path: 'partner-details',
      label: 'Partner details',
      element: <PartnerDetails partner={partner} />,
    },
    {
      path: 'partner-contract-download',
      label: 'Download contract',
      element: <PartnerDownloadContract partner={partner} />,
    },

    {
      path: 'delete-account',
      label: 'Delete account',
      element: <DeleteAccount />,
    },
  ]

  return (
    <AsideMenu
      menuTitle="Partner settings"
      routes={USER_ROUTES}
      indexRoute="/partner"
    />
  )
}

export default PartnerPage
