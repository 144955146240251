import axios from 'axios'

import { useQuery } from 'react-query'

import { Partner } from '../../../types'

export const useSession = () =>
  useQuery('session', async () => {
    const { data } = await axios.get<Partner>('session')

    return data
  })
