import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import useRemoveApp from '../../../../hooks/api/mutations/useRemoveApp'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import FormField from '../../../../components/FormField'

interface DeleteProps {
  appId: string
}

const RemoveApp: React.FC<DeleteProps> = ({ appId }) => {
  const removeApp = useRemoveApp(appId)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  const navigate = useNavigate()

  return (
    <>
      <Card
        title="Remove app"
        helpText="The app will be disconnected by your partner account."
        danger
      >
        <form
          onSubmit={handleSubmit(() =>
            removeApp.mutateAsync().then(() => navigate(`/apps`))
          )}
        >
          <div className="mb-5">
            <FormField
              fieldName="confirmationCheckbox"
              type="checkbox"
              register={register}
              checkboxLabel={
                <span className="ml-2">
                  I confirm that I want to remove this app from my account
                </span>
              }
              validation={{
                required: 'Please, confirm that you want to remove this app',
              }}
              error={errors.confirmationCheckbox}
            />
          </div>

          <Button type="submit" color="red" loading={removeApp.isLoading}>
            Remove app
          </Button>
        </form>
      </Card>
    </>
  )
}

export default RemoveApp
