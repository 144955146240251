import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

import { Customer } from '../../../types'

const usePatchCustomer = (customerId?: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: Customer) => {
      const { data: result } = await axios.patch<Customer>(
        `/customers/${customerId}`,
        values
      )
      return result
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(['customer', customerId?.toString()])
          queryClient.invalidateQueries('customers')
          toast.success('Customer changes saved successfully', {
            position: 'bottom-right',
          })
        }
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchCustomer
