import React from 'react'
import { Link } from 'react-router-dom'

import Card from '../../../components/Card'

const DeleteAccount: React.FC = () => {
  return (
    <Card title="Delete your React Bricks partner account" danger>
      <p>
        To delete your React Bricks partner account, please contact us at the
        following email:
      </p>
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = 'mailto:support@reactbricks.com'
          e.preventDefault()
        }}
        className="text-blue-500 underline"
      >
        support@reactbricks.com
      </Link>
    </Card>
  )
}

export default DeleteAccount
