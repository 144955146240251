import React from 'react'

import classNames from 'classnames'

import { Oval } from 'react-loader-spinner'

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
  full?: boolean
  className?: string
  disabled?: boolean
  loading?: boolean
  color?: 'pink' | 'accent' | 'green' | 'blue' | 'red' | 'gray' | 'cyan'
  link?: boolean
  form?: string
  onClick?: (event: React.FormEvent) => void
  children?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  full = false,
  className,
  disabled = false,
  loading = false,
  color,
  link = false,
  children,
  form,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      form={form}
      className={classNames(
        'block focus:shadow-outline focus:outline-none text-white font-semibold leading-5 rounded transition-colors duration-150',
        {
          'text-white py-2 px-5': !link,
        },
        { 'text-accent-600 hover:text-accent-700 bg-transparent': link },
        {
          'bg-pink-500 hover:bg-pink-600':
            color === 'pink' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'pink' && (disabled || loading),
        },
        {
          'bg-accent-600 hover:bg-accent-700':
            color === 'accent' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'accent' && (disabled || loading),
        },
        {
          'bg-blue-500 hover:bg-blue-600':
            color === 'blue' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'blue' && (disabled || loading),
        },
        {
          'bg-lime-500 hover:bg-lime-600':
            color === 'green' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'green' && (disabled || loading),
        },
        {
          'bg-red-500 hover:bg-red-600':
            color === 'red' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'red' && (disabled || loading),
        },
        {
          'bg-gray-500 hover:bg-gray-600':
            color === 'gray' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'gray' && (disabled || loading),
        },
        {
          'bg-cyan-500 hover:bg-cyan-600':
            color === 'cyan' && !(disabled || loading),
        },
        {
          'bg-gray-300 hover:bg-gray-300 cursor-not-allowed':
            color === 'cyan' && (disabled || loading),
        },
        { 'w-full py-3': full },
        className
      )}
      onClick={onClick}
    >
      {loading ? (
        <div className="flex justify-center">
          <div className="mr-2">
            <Oval color={link ? '#6c63ff' : '#ffffff'} height={20} width={20} />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
