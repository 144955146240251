import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { useClickOutsideListenerRef } from '../hooks/useClickOutsideListenerRef'
import useLogout from '../hooks/api/mutations/useLogout'

import HeaderMenu from './HaederMenu'

import './Header.css'

import blankAvatar from '../images/avatar.svg'
import logo from '../images/reactbricks-logo.svg'

import { FiUser } from 'react-icons/fi'

import { useSession } from '../hooks/api/queries/useSessions'

const Header: React.FC = () => {
  const navigate = useNavigate()

  const [userMenuOpen, setUserMenuOpen] = useState<undefined | boolean>()

  const { data: partner } = useSession()

  const logout = useLogout()

  const location = useLocation()

  const handleLogout = () => {
    logout.mutateAsync().then(() => navigate('/'))
  }

  const ref = useClickOutsideListenerRef(() => setUserMenuOpen(false))

  if (!partner) {
    return null
  }

  const foto = partner.avatarUrl || blankAvatar

  return (
    <header className="bg-white border-b border-gray-200">
      {partner && (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="bg-white">
            <div className="flex items-center justify-between h-16">
              <div className="left flex items-center">
                <img src={logo} alt="React Bricks" className="w-32 mr-4" />
              </div>
              <div
                className="right flex items-center relative cursor-pointer"
                onClick={() => {
                  setUserMenuOpen((userMenuOpen) => !userMenuOpen)
                }}
                ref={ref}
              >
                <div className="w-8 h-8 border rounded-full flex justify-center items-center text-accent-600 bg-gray-50 border-gray-300">
                  {foto ? (
                    <img
                      src={partner.avatarUrl || blankAvatar}
                      alt={partner.companyName}
                      className="rounded-full"
                    />
                  ) : (
                    <FiUser />
                  )}
                </div>
                <div className="text-sm text-gray-600 ml-2 leading-none">
                  {partner.companyName}
                </div>
                {userMenuOpen && (
                  <HeaderMenu
                    //onPause={handlePause}
                    onLogout={handleLogout}
                  />
                )}
              </div>
            </div>
          </nav>
          <nav className="bg-white">
            <div className="flex items-center justify-start text-md">
              <NavLink
                to="customers"
                className={({ isActive }) =>
                  isActive
                    ? 'pt-1 pb-3 mr-8 border-b-4 border-pink-500 text-gray-900'
                    : 'pt-1 pb-3 mr-8 text-gray-600 border-b-4 border-transparent'
                }
              >
                Customers
              </NavLink>
              <NavLink
                to="apps"
                className={({ isActive }) =>
                  isActive || location.pathname.substring(0, 4) === '/app'
                    ? 'pt-1 pb-3 mr-8 border-b-4 border-pink-500 text-gray-900'
                    : 'pt-1 pb-3 mr-8 text-gray-600 border-b-4 border-transparent'
                }
              >
                Apps
              </NavLink>
              <NavLink
                to="partner"
                className={({ isActive }) =>
                  isActive
                    ? 'pt-1 pb-3 mr-8 border-b-4 border-pink-500 text-gray-900'
                    : 'pt-1 pb-3 mr-8 text-gray-600 border-b-4 border-transparent'
                }
              >
                Partner settings
              </NavLink>
            </div>
          </nav>
        </div>
      )}
    </header>
  )
}

export default Header
