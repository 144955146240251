import React from 'react'
import { useForm } from 'react-hook-form'

import { useGetCustomerAppSharedNotes } from '../../../../hooks/api/queries/useGetCustomerAppSharedNotes'
import usePatchCustomerAppSharedNotes from '../../../../hooks/api/mutations/usePatchCustomerAppSharedNotes'

import Card from '../../../../components/Card'
import FormField from '../../../../components/FormField'
import Button from '../../../../components/Button'

interface SharedNotesProps {
  appId: string
}

const SharedNotes: React.FC<SharedNotesProps> = ({ appId }) => {
  const { data, isLoading } = useGetCustomerAppSharedNotes(appId)

  const mutateNote = usePatchCustomerAppSharedNotes(appId)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()

  return (
    <>
      <Card title="Shared notes">
        {data?.sharedNotes !== undefined && (
          <form onSubmit={handleSubmit((data) => mutateNote.mutateAsync(data))}>
            <div className="mb-6">
              <FormField
                fieldName="sharedNotes"
                type="textarea"
                rows={15}
                register={register}
                validation={{ required: 'A note is required' }}
                error={errors.sharedNotes}
                defaultValue={data.sharedNotes}
              />
            </div>
            <div>
              <Button
                type="submit"
                color="cyan"
                loading={isLoading}
                className="flex-1"
              >
                update
              </Button>
            </div>
          </form>
        )}
      </Card>
    </>
  )
}

export default SharedNotes
