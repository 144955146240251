import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'

interface CardProps {
  title: string
  helpText?: string
  danger?: boolean
  actionButton?: ReactElement
  //plan?: Plan
  //available?: boolean
  children: ReactNode
}

const Card: React.FC<CardProps> = ({
  title,
  helpText,
  danger = false,
  actionButton = false,
  //available = true,
  //plan,
  children,
}) => {
  return (
    <div
      className={classNames('border p-6 rounded-md mb-8', {
        'border-red-500': danger,
      })}
    >
      {actionButton ? (
        <div
          className={classNames({
            'md:flex justify-between items-center': actionButton,
          })}
        >
          <div className="flex items-center space-x-4 mb-4">
            <h2
              className={classNames('text-xl font-semibold', {
                'text-red-600': danger,
                'text-pink-600': !danger,
              })}
            >
              {title}
            </h2>
            {/* {!available && (
              <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
                Not available for this plan
              </div>
            )}
            {plan && <PlanPill plan={plan} />} */}
          </div>
          {actionButton}
        </div>
      ) : (
        <div className="flex items-center space-x-4 mb-4">
          <h2
            className={classNames('text-xl font-semibold', {
              'text-red-600': danger,
              'text-pink-600': !danger,
            })}
          >
            {title}
          </h2>
          {/* {!available && (
            <div className="text-xs font-bold uppercase tracking-wide bg-gray-200 text-gray-500 py-px px-2 rounded-sm">
              Not available for this plan
            </div>
          )}
          {plan && <PlanPill plan={plan} />} */}
        </div>
      )}
      {helpText && (
        <p
          className={classNames('mb-6 text-sm max-w-lg', {
            'text-gray-700': danger,
            'text-gray-600': !danger,
          })}
        >
          {helpText}
        </p>
      )}
      {children}
    </div>
  )
}

export default Card
