import axios from 'axios'

import { useQuery } from 'react-query'
import { App } from '../../../types'

import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'

const fetchApp = async (
  navigate: NavigateFunction,
  customerId?: string,
  appId?: string
) => {
  if (
    (appId && appId.length !== 36) ||
    (customerId && customerId.match(/^-?\d+$/) === null)
  ) {
    navigate('/apps')
    toast.error('Not valid app', {
      position: 'bottom-right',
      autoClose: 5000,
    })
    return undefined
  }

  const { data: data } = await axios.get<App>(
    `customers/app/${customerId}/${appId}`
  )

  return data
}

export const useGetApp = (
  navigate: NavigateFunction,
  customerId?: string,
  appId?: string
) => {
  return useQuery(['app', appId], () => fetchApp(navigate, customerId, appId), {
    enabled: !!appId,
    refetchOnWindowFocus: false,
  })
}
