import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

import { Customer } from '../../../types'

const useRemoveCustomer = (customerId: number) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () =>
      await axios
        .delete<Customer>(`/customers/${customerId}`)
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('customers')
        toast.success(`Customer ${data.companyName} removed successfully`, {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useRemoveCustomer
