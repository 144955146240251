import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

import { useNavigate } from 'react-router-dom'
import { App } from '../../../types'

const useRemoveApp = (appId: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(
    async () =>
      await axios
        .delete<App>(`/customers/app/${appId}`)
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('apps')
        navigate('/apps')
        toast.success(`App ${data.name} removed successfully`, {
          position: 'bottom-right',
        })
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default useRemoveApp
