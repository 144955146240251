import axios from 'axios'

import { useQuery } from 'react-query'

import { Customer } from '../../../types'

const fetchCustomers = async () => {
  const { data: result } = await axios.get<Customer[]>('customers')

  return result
}

export const useGetAllCustomers = () =>
  useQuery(['customers'], () => fetchCustomers())
