import { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetApp } from '../../../../hooks/api/queries/useGetApp'

import RemoveApp from './RemoveApp'
import Plan from './Plan'
import Invoices from './Invoices'
import SharedNotes from './SharedNotes'

import PlanPill from '../../../../components/PlanPill'
import AsideMenu from '../../../../components/AsideMenu'

interface AppRoute {
  path: string
  label: string
  element: ReactElement
  separator?: boolean
  disabled?: boolean
}

const App: React.FC = () => {
  const { customerId, appId } = useParams()

  const navigate = useNavigate()

  const { data } = useGetApp(navigate, customerId, appId)

  if (!appId) {
    return null
  }

  const APP_ROUTES: AppRoute[] = [
    {
      path: 'plan',
      label: 'Your Plan',
      element: <Plan appId={appId} planName={data?.plan} />,
    },
    {
      path: 'invoices',
      label: 'Invoices',
      element: <Invoices appId={appId} />,
    },
    {
      path: 'shared-notes',
      label: 'Shared notes',
      element: <SharedNotes appId={appId} />,
    },
    {
      path: 'remove-app',
      label: 'Remove App',
      element: <RemoveApp appId={appId} />,
    },
  ]

  return (
    data && (
      <div>
        <div className="bg-gray-50 border-b border-gray-200">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="flex space-x-3 items-center mb-4">
              <h1 className="text-4xl font-semibold leading-5">
                {data?.name || ''}
              </h1>

              <PlanPill
                plan={data?.plan}
                isLiveDemo={
                  data?.liveDemo && data?.liveDemo.status === 'ACTIVE'
                }
                isBoosted={
                  data?.subscription && data?.subscription.description
                    ? data?.subscription.description ===
                      'Boosted Developer Plan'
                    : false
                }
              />
            </div>
            <div className="inline-block text-xs py-0.5 px-2 bg-gray-600/10 rounded text-gray-500">
              {data?.id}
            </div>
          </div>
        </div>

        <AsideMenu
          menuTitle={`App Settings`}
          routes={APP_ROUTES}
          indexRoute={`/app/${customerId}/${appId}`}
        />
      </div>
    )
  )
}

export default App
