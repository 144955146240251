import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

import { Partner } from '../../../types'

const usePatchPartner = (id: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: any) => {
      const { data } = await axios.patch<Partner>(`/partners/${id}`, values)
      return data
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries('session')
          toast.success('Settings saved successfully', {
            position: 'bottom-right',
          })
        }
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchPartner
