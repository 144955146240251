import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

import { Customer } from '../../../types'

const useAddCustomer = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (values: Customer) =>
      await axios.post<Customer>(`/customers`, values).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['customer', data.id], data)
        queryClient.invalidateQueries(['customers'])
        toast.success('Customer add successfully', {
          position: 'bottom-right',
        })
      },
    }
  )
}

export default useAddCustomer
