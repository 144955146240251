import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

const useAddApp = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (values: any) => {
      const response = await axios.post<any>(
        `/customers/${values.customerId}/apps`,
        values
      )
      return response
    },
    {
      onSuccess: (data) => {
        if (data?.status === 201) {
          queryClient.invalidateQueries(['apps'])
          toast.success('App add successfully', {
            position: 'bottom-right',
          })
        }
      },
    }
  )
}

export default useAddApp
