import React from 'react'

import PlanPill from './PlanPill'

import classNames from 'classnames'

import { FaLock } from 'react-icons/fa'
import { App } from '../types'

interface CustomerAppProps {
  app: App
}

const CustomerApp: React.FC<CustomerAppProps> = ({ app }) => {
  return (
    <div
      className={classNames(
        {
          'px-6 py-5 rounded-lg bg-white shadow-md hover:shadow-2xl relative transition-shadow duration-150':
            !app.hidden,
        },
        {
          'px-6 py-5 rounded-lg bg-gray-200 relative h-[126px] opacity-80 shadow-md hover:shadow-2xl transition-shadow duration-150':
            app.hidden,
        }
      )}
    >
      {!app.hidden && (
        <div className="absolute top-2 right-0 rounded" style={{ right: -2 }}>
          <PlanPill
            plan={app.plan}
            //isLabel
            //isLiveDemo={!!app.liveDemo && app.liveDemo.status === 'ACTIVE'}
            // isBoosted={subscription?.description === 'Boosted Developer Plan'}
          />
        </div>
      )}

      {app.hidden && (
        <div
          className="absolute top-4 right-0 rounded pr-4"
          style={{ right: -2 }}
        >
          <FaLock className="opacity-80" />
        </div>
      )}

      {!app.hidden && app.lastInvoice && (
        <div className="absolute top-10 right-0 pr-4 " style={{ right: -2 }}>
          <p className="font-semibold">Last invoice</p>
          <p className="text-gray-600 text-sm">
            {new Date(app.lastInvoice?.invoiceDate).toLocaleDateString()}
          </p>
          <p className="text-gray-600 text-sm">
            {'€' + ' ' + app.lastInvoice?.totalAmount}
          </p>
        </div>
      )}
      <div
        className={classNames(
          'uppercase tracking-wide text-pink-700 text-xs truncate',
          { 'opacity-80': app.hidden }
        )}
      >
        {app.customer.companyName}
      </div>
      <div className="mb-5 truncate">
        <h2
          className={classNames('text-2xl font-semibold', {
            'opacity-80': app.hidden,
          })}
        >
          {app.name}
        </h2>
      </div>
      {!app.hidden && (
        <div className="flex flex-wrap space-x-3">
          <span
            className={classNames(
              'py-1 px-2 leading-none text-gray-700 rounded text-xs font-semibold uppercase',
              { 'bg-gray-200': app.partnerPortalStatus === 'INACTIVE' },
              {
                'bg-yellow-200': app.partnerPortalStatus === 'PAYMENT OVERDUE',
              },
              { 'bg-green-200': app.partnerPortalStatus === 'ACTIVE' }
            )}
          >
            {app.partnerPortalStatus}
          </span>
        </div>
      )}
    </div>
  )
}

export default CustomerApp
