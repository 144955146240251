import React, { useEffect, useRef } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import Header from '../../components/Header'

import Apps from './Apps'
import App from './Apps/App'
import Customers from './Customers'
import Customer from './Customers/Customer'
import Partner from './Partner'

import { useSession } from '../../hooks/api/queries/useSessions'

const LoggedApp: React.FC = () => {
  const navigate = useRef(useNavigate())

  const { data: data, isLoading } = useSession()

  useEffect(() => {
    if (!data && !isLoading) {
      navigate.current('/')
    }
  }, [data, isLoading])

  return (
    data &&
    !isLoading && (
      <div className="logged-app">
        <Header />

        <div className="logged">
          <Routes>
            <Route path="apps" element={<Apps />} />
            <Route path="apps/:customerId" element={<Apps />} />
            <Route path="app/:customerId/:appId/*" element={<App />} />
            <Route path="customers" element={<Customers />} />
            <Route path="customers/:customerId/*" element={<Customer />} />
            <Route path="partner/*" element={<Partner />} />
            <Route path="/*" element={<Apps />} />
          </Routes>
        </div>
      </div>
    )
  )
}

export default LoggedApp
