import React from 'react'

import { useGetAppInvoices } from '../../../../hooks/api/queries/useGetAppInvoices'

import Card from '../../../../components/Card'

import classNames from 'classnames'

import { Tooltip } from 'react-tooltip'

import { Invoice } from '../../../../types'

interface InvoicesProps {
  appId: string
}

const Invoices: React.FC<InvoicesProps> = ({ appId }) => {
  const { data: data } = useGetAppInvoices(appId)

  return (
    <>
      <Card title="Invoices">
        {data?.length !== 0 && (
          <>
            <hr />
            <table className="table-fixed w-full mb-12">
              <tbody>
                {data?.map((invoice: Invoice, index: number) => {
                  const tooltip =
                    invoice.paymentStatus.toUpperCase() === 'PAID'
                      ? `Paid on ${new Date(
                          invoice.paidAt
                        ).toLocaleDateString()}`
                      : `Due date : ${new Date(
                          invoice.dueDate
                        ).toLocaleDateString()}`
                  return (
                    <tr key={invoice.id}>
                      <td
                        className={classNames(
                          'w-1/3 py-3 text-sm font-semibold pr-6',
                          {
                            'border-b': data?.length !== index + 1,
                          }
                        )}
                      >
                        {new Date(invoice.invoiceDate).toLocaleDateString()}
                      </td>
                      <td
                        className={classNames('w-1/3 py-3 text-sm', {
                          'border-b': data?.length !== index + 1,
                        })}
                      >
                        {`€ ${invoice.totalAmount}`}
                      </td>
                      <td
                        className={classNames('w-1/3 py-3 text-sm', {
                          'border-b': data?.length !== index + 1,
                        })}
                      >
                        <span
                          className={classNames(
                            'py-1 px-2 leading-none text-gray-700 rounded text-xs font-semibold uppercase',
                            {
                              'bg-gray-200':
                                invoice.paymentStatus.toUpperCase() ===
                                'PENDING',
                            },
                            {
                              'bg-green-200':
                                invoice.paymentStatus.toUpperCase() === 'PAID',
                            },
                            'paymentStatus'
                          )}
                          data-tooltip-id="paymentStatus"
                          data-tooltip-content={tooltip}
                          data-tooltip-place="bottom"
                        >
                          <Tooltip id="paymentStatus" />

                          {invoice.paymentStatus}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
        {data?.length === 0 && <p className="text-xl">No invoice to show...</p>}
      </Card>
    </>
  )
}

export default Invoices
