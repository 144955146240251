import axios from 'axios'

import { useQuery } from 'react-query'
import { PlanRow } from '../../../types'

const fetchAppPlanRows = async (appId: string) => {
  const { data: result } = await axios.get<PlanRow[]>(
    `customers/app/plan-rows/${appId}`
  )

  return result
}

export const useGetAppPlanRows = (appId: string) =>
  useQuery(['planRows', appId], () => fetchAppPlanRows(appId))
