import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import useLogin from '../../hooks/api/mutations/useLogin'

import { Helmet } from 'react-helmet'

import imgLogo from '../../images/reactbricks-logo-v.svg'

import FormField from '../../components/FormField'
import Button from '../../components/Button'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type LoginData = {
  email: string
  password: string
}

const Login: React.FC = () => {
  // HOOK

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginData>()

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const login = useLogin()

  const handleLogin = handleSubmit(({ email, password }) => {
    setLoading(true)
    login
      .mutateAsync({ email, password })
      .then((res) => {
        if (res) {
          navigate('/apps')
        }
      })
      .finally(() => setLoading(false))
  })

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1508108712903-49b7ef9b1df8?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <img src={imgLogo} alt="logo" className="w-32" />
        </a>
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        <h1 className="text-center font-bold text-gray-600 mb-6">
          Welcome to the <span className="text-pink-500">Partner Portal</span>
        </h1>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <FormField
              fieldName="email"
              label="Email"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="email"
              register={register}
              validation={{
                required: 'Enter your email address',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address',
                },
              }}
              error={errors.email}
            />
          </div>
          <div className="mb-4">
            <FormField
              fieldName="password"
              label="Password"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="password"
              register={register}
              validation={{ required: 'Enter your password' }}
              error={errors.password}
            />
          </div>
          <div className="mb-6">
            <FormField
              fieldName="keepLoggedIn"
              checkboxLabel="Keep me logged in"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="checkbox"
              register={register}
            />
          </div>
          <Button full color="cyan" type="submit" loading={loading}>
            Log in
          </Button>
        </form>

        <div className="mt-6 flex justify-between text-sm">
          <Link
            to="https://dashboard.reactbricks.com/forgot-password"
            className="text-blue-600 hover:text-blue-700"
          >
            Forgot password?
          </Link>
          <a
            href="https://reactbricks.com/sign-up"
            className="text-blue-600 hover:text-blue-700"
          >
            Sign up for free
          </a>
        </div>
      </div>
      <div className="mt-4 mb-16 text-gray-700 text-center text-sm">
        © 2022{' '}
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-700"
        >
          ReactBricks.com
        </a>
      </div>
      <ToastContainer limit={1} />
    </div>
  )
}

export default Login
