import Card from '../../../components/Card'
import Button from '../../../components/Button'

import { Partner } from '../../../types'
import useDownloadContract from '../../../hooks/api/mutations/useDownloadContract'
import { useState } from 'react'

interface partnerDownloadContractProps {
  partner: Partner
}

const PartnerDownloadContract: React.FC<partnerDownloadContractProps> = ({
  partner,
}) => {
  const download = useDownloadContract(partner.id)

  const [error, setError] = useState<boolean>(false)

  const handleDownload = async () => {
    const { data, status, headers } = await download.mutateAsync()

    if (status !== 200) {
      setError(true)
      return
    } else {
      setError(false)
    }

    const disposition = headers['content-disposition']
    let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1]
    if (filename.toLowerCase().startsWith("utf-8''")) {
      filename = decodeURIComponent(filename.replace("utf-8''", ''))
    } else {
      filename = filename.replace(/['"]/g, '')
    }

    const url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Card
      title="Contract"
      helpText="Click the button to download your contract."
    >
      {partner && !error && (
        <Button color="blue" onClick={handleDownload}>
          Download
        </Button>
      )}
      {error && (
        <>
          <p className="mb-2">Something went wrong...</p>
          <Button color="blue" onClick={handleDownload}>
            Retry
          </Button>
        </>
      )}
    </Card>
  )
}

export default PartnerDownloadContract
