import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

import { useGetAllCustomers } from '../../../hooks/api/queries/useGetAllCustomers'
import useAddCustomer from '../../../hooks/api/mutations/useAddCustomer'

import Loading from '../../../components/Loading'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'

import noApps from '../../../images/no_app.svg'

import { modalCustomStyles } from '../../../styles/modalStyles'

import classNames from 'classnames'

import { MdOutlineApps } from 'react-icons/md'
import { IoMdSearch } from 'react-icons/io'
import { FiPlusCircle } from 'react-icons/fi'

import { Tooltip } from 'react-tooltip'
import { Customer } from '../../../types'

const Customers = () => {
  const [addNewCustomer, setAddNewCustomer] = useState<boolean>(false)

  const openAddCustomerModal = () => {
    setAddNewCustomer(true)
  }
  const closeAddCustomerModal = () => {
    setAddNewCustomer(false)
  }

  const { data: customers, ...customersQuery } = useGetAllCustomers()

  const addCustomer = useAddCustomer()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-semibold leading-tight mb-4">
          {'Customers'}
          {customersQuery.isFetching ? ' ...' : null}
        </h1>

        {customersQuery.isLoading ? (
          <Loading />
        ) : customers && customers.length > 0 ? (
          <div className="md:flex">
            <Button
              color="green"
              className="flex items-center pl-3 relative"
              onClick={openAddCustomerModal}
            >
              <FiPlusCircle className={`mr-2 text-lime-200`} />
              Add new customer
            </Button>
          </div>
        ) : (
          <>
            <div className="flex flex-wrap sm:flex-no-wrap max-w-sm mx-auto sm:max-w-full sm:mx-0 items-center justify-center sm:justify-between py-12">
              <img
                src={noApps}
                alt="noApps"
                className="sm:w-1/3 w-full mb-12 sm:mb-0"
              />
              <div className="sm:w-2/3 pl-20 w-full text-center sm:text-left">
                <div className="text-xl mb-12 text-gray-600">
                  <p className="mb-1">
                    You haven't registered any customer yet.
                  </p>
                  <p className="font-bold">But you're just a click away!</p>
                </div>
                <div className="flex justify-center sm:justify-start">
                  <Button
                    color="cyan"
                    className="flex items-center py-3 px-6 text-lg"
                    onClick={openAddCustomerModal}
                  >
                    <FiPlusCircle className="mr-2 text-cyan-200" />
                    Add a customer now
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {customers && customers.length > 0 && (
        <div
          className="bg-gray-50 border-t border-gray-200"
          style={{ minHeight: 400 }}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <ul className="w-2/3 p-4 bg-white">
              {customers?.map((customer: Customer, index: number) => (
                <li
                  key={customer.id}
                  className={classNames('my-4 w-full', {
                    'border-t': index !== 0,
                  })}
                >
                  <div className="flex justify-between my-2 items-center">
                    <div>
                      <p className="text-lg">{customer.companyName}</p>
                    </div>
                    <div className="flex gap-x-6">
                      <style>{`.tooltip-customer {
                          padding:4px;
                        }`}</style>
                      <Link to={`/customers/${customer.id}`}>
                        <IoMdSearch
                          className="inline text-zinc-400 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125 hover:text-zinc-500 duration-300"
                          size={'24px'}
                          data-tooltip-id="showDetails"
                          data-tooltip-content={'Show details'}
                          data-tooltip-place="bottom"
                        />

                        <Tooltip
                          id="showDetails"
                          className="tooltip-customer"
                        />
                      </Link>
                      <Link to={`/apps/${customer.id}`}>
                        <MdOutlineApps
                          className="inline text-zinc-400 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125 hover:text-zinc-500 duration-300"
                          size={'22px'}
                          data-tooltip-id="showApps"
                          data-tooltip-content={'Show apps'}
                          data-tooltip-place="bottom"
                        />
                        <Tooltip id="showApps" className="tooltip-customer" />
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <Modal
        isOpen={!!addNewCustomer}
        onRequestClose={closeAddCustomerModal}
        style={modalCustomStyles}
        ariaHideApp={false}
      >
        {addNewCustomer && (
          <div className="px-12 py-8 max-w-xl">
            <h2 className="text-xl font-semibold text-center mb-6">
              Add Customer
            </h2>

            <form
              onSubmit={handleSubmit((data) =>
                addCustomer.mutateAsync(data).then(closeAddCustomerModal)
              )}
            >
              <div className="mb-6">
                <div className="mb-6">
                  <FormField
                    fieldName="companyName"
                    type="text"
                    label="Company name"
                    register={register}
                    validation={{ required: 'A company name is required' }}
                    error={errors.companyName}
                  />
                </div>
                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="technicalContactName"
                      type="text"
                      label="Technical contact name"
                      register={register}
                      validation={{
                        required: 'A technical contact name is required',
                      }}
                      error={errors.technicalContactName}
                    />
                  </div>
                  <div className="w-1/2">
                    <FormField
                      fieldName="email"
                      type="email"
                      label="Email"
                      register={register}
                      validation={{ required: 'An email is required' }}
                      error={errors.email}
                    />
                  </div>
                </div>
                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="address"
                      type="text"
                      label="Address"
                      register={register}
                      validation={{ required: 'An address is required' }}
                      error={errors.address}
                    />
                  </div>
                  <div className="w-1/2">
                    <FormField
                      fieldName="city"
                      type="text"
                      label="City"
                      register={register}
                      validation={{ required: 'A city is required' }}
                      error={errors.city}
                    />
                  </div>
                </div>
                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="province"
                      type="text"
                      label="Province"
                      register={register}
                      validation={{ required: 'A province is required' }}
                      error={errors.province}
                    />
                  </div>
                  <div className="w-1/2">
                    <FormField
                      fieldName="country"
                      type="text"
                      label="Country"
                      register={register}
                      validation={{ required: 'A country is required' }}
                      error={errors.country}
                    />
                  </div>
                </div>
                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="zipCode"
                      type="text"
                      label="Zip code"
                      register={register}
                      validation={{ required: 'A zip code is required' }}
                      error={errors.zipCode}
                    />
                  </div>
                  <div className="w-1/2">
                    <FormField
                      fieldName="phone"
                      type="text"
                      label="Phone number"
                      register={register}
                      validation={{ required: 'A phone number is required' }}
                      error={errors.companyName}
                    />
                  </div>
                </div>

                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="fiscalCode"
                      type="text"
                      label="Fiscal code"
                      register={register}
                      validation={{ required: 'A fiscal code is required' }}
                      error={errors.fiscalCode}
                    />
                  </div>

                  <div className="w-1/2">
                    <FormField
                      fieldName="vatId"
                      type="text"
                      label="VAT ID"
                      register={register}
                      validation={{ required: 'VAT ID is required' }}
                      error={errors.vatId}
                    />
                  </div>
                </div>

                <div className="flex gap-x-4 mb-6">
                  <div className="w-1/2">
                    <FormField
                      fieldName="contractSignerName"
                      type="text"
                      label="Contract signer name"
                      register={register}
                      validation={{
                        required: 'A contract signer name is required',
                      }}
                      error={errors.contractSignerName}
                    />
                  </div>
                  <div className="w-1/2">
                    <FormField
                      fieldName="contractSignerEmail"
                      type="email"
                      label="Contract signer email"
                      register={register}
                      validation={{
                        required: 'A contract signer email is required',
                      }}
                      error={errors.contractSignerEmail}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <Button
                  color="gray"
                  onClick={closeAddCustomerModal}
                  className="flex-1"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="cyan"
                  loading={addCustomer.isLoading}
                  className="flex-1"
                >
                  Add customer
                </Button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default Customers
