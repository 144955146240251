import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

import { toast } from 'react-toastify'

const usePatchCustomerSharedNotes = (customerId?: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: any) => {
      const { data } = await axios.patch<{ sharedNotes: string }>(
        `/customers/${customerId}/shared-notes`,
        values
      )
      return data
    },
    {
      onSuccess: (data) => {
        if (typeof data.sharedNotes === 'string') {
          queryClient.invalidateQueries(['customer', customerId?.toString()])
          toast.success('Notes saved successfully', {
            position: 'bottom-right',
          })
        }
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
      },
    }
  )
}

export default usePatchCustomerSharedNotes
