import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import useRemoveCustomer from '../../../../hooks/api/mutations/useRemoveCustomer'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import FormField from '../../../../components/FormField'

interface DeleteProps {
  customerId: number
}

const RemoveCustomer: React.FC<DeleteProps> = ({ customerId }) => {
  const removeCustomer = useRemoveCustomer(customerId)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  const navigate = useNavigate()

  return (
    <>
      <Card
        title="Remove customer"
        helpText="The customer will be disconnected by your partner account."
        danger
      >
        <form
          onSubmit={handleSubmit(() =>
            removeCustomer.mutateAsync().then(() => navigate(`/customers`))
          )}
        >
          <div className="mb-5">
            <FormField
              fieldName="confirmationCheckbox"
              type="checkbox"
              register={register}
              checkboxLabel={
                <span className="ml-2">
                  I confirm that I want to delete this customer
                </span>
              }
              validation={{
                required:
                  'Please, confirm that you want to remove this customer',
              }}
              error={errors.confirmationCheckbox}
            />
          </div>

          <Button type="submit" color="red" loading={removeCustomer.isLoading}>
            Remove customer
          </Button>
        </form>
      </Card>
    </>
  )
}

export default RemoveCustomer
