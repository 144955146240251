import axios from 'axios'

import { useQuery } from 'react-query'
import { Invoice } from '../../../types'

const fetchInvoices = async (appId: string) => {
  const { data: result } = await axios.get<Invoice[]>(
    `customers/app/${appId}/invoices`
  )

  return result
}

export const useGetAppInvoices = (appId: string) =>
  useQuery(['appInvoices', appId], () => fetchInvoices(appId))
