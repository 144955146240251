import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'
import { Partner } from '../../../types'

interface ILoginCredentials {
  email: string
  password: string
}

const login = async ({ email, password }: ILoginCredentials) => {
  const { data: data } = await axios.post<{
    partner: Partner
  }>(`/session`, {
    email,
    password,
  })

  return data
}

const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ email, password }: ILoginCredentials) => {
      return login({ email, password })
    },
    {
      onSuccess: (data) => {
        if (data && data.partner) {
          queryClient.invalidateQueries('useSession')
          queryClient.invalidateQueries(['customers'])
          queryClient.invalidateQueries(['apps'])
          queryClient.setQueryData('useSession', data.partner)
        }
      },
    }
  )
}

export default useLogin
