import React from 'react'
import { useForm } from 'react-hook-form'

import usePatchCustomer from '../../../../hooks/api/mutations/usePatchCustomer'

import Card from '../../../../components/Card'
import FormField from '../../../../components/FormField'
import Button from '../../../../components/Button'
import { Customer } from '../../../../types'

interface SharedNotesCustomerProps {
  customer?: Customer
}

const CustomerDetailsPage: React.FC<SharedNotesCustomerProps> = ({
  customer,
}) => {
  const mutate = usePatchCustomer(customer?.id)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()

  return (
    <>
      <Card title="Customer data">
        {customer && (
          <form onSubmit={handleSubmit((data) => mutate.mutateAsync(data))}>
            <div className="mb-6">
              <div className="mb-6">
                <FormField
                  fieldName="companyName"
                  type="text"
                  label="Company name"
                  register={register}
                  validation={{ required: 'A company name is required' }}
                  error={errors.companyName}
                  defaultValue={customer.companyName}
                />
              </div>
              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    fieldName="technicalContactName"
                    type="text"
                    label="Technical contact name"
                    register={register}
                    validation={{
                      required: 'A technical contact name is required',
                    }}
                    error={errors.technicalContactName}
                    defaultValue={customer.technicalContactName}
                  />
                </div>

                <div className="w-1/2">
                  <FormField
                    fieldName="email"
                    type="email"
                    label="Email"
                    register={register}
                    validation={{ required: 'An email is required' }}
                    error={errors.email}
                    defaultValue={customer.email}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    fieldName="address"
                    type="text"
                    label="Address"
                    register={register}
                    validation={{ required: 'An address is required' }}
                    error={errors.address}
                    defaultValue={customer.address}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    fieldName="city"
                    type="text"
                    label="City"
                    register={register}
                    validation={{ required: 'A city is required' }}
                    error={errors.city}
                    defaultValue={customer.city}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    fieldName="province"
                    type="text"
                    label="Province"
                    register={register}
                    validation={{ required: 'A province is required' }}
                    error={errors.province}
                    defaultValue={customer.province}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    fieldName="country"
                    type="text"
                    label="Country"
                    register={register}
                    validation={{ required: 'A country is required' }}
                    error={errors.country}
                    defaultValue={customer.country}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    //TODO MUST BE ONLY NUMERIC
                    fieldName="zipCode"
                    type="text"
                    label="Zip code"
                    register={register}
                    validation={{ required: 'A zip code is required' }}
                    error={errors.zipCode}
                    defaultValue={customer.zipCode}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    fieldName="phone"
                    type="text"
                    label="Phone number"
                    register={register}
                    validation={{ required: 'A phone number is required' }}
                    error={errors.phone}
                    defaultValue={customer.phone}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    fieldName="fiscalCode"
                    type="text"
                    label="Fiscal code"
                    register={register}
                    validation={{ required: 'A fiscal code is required' }}
                    error={errors.fiscalCode}
                    defaultValue={customer.fiscalCode}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    fieldName="vatId"
                    type="text"
                    label="VAT ID"
                    register={register}
                    validation={{ required: 'A VAT ID is required' }}
                    error={errors.vatId}
                    defaultValue={customer.vatId}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 mb-6">
                <div className="w-1/2">
                  <FormField
                    fieldName="contractSignerName"
                    type="text"
                    label="Contract signer name"
                    register={register}
                    validation={{
                      required: 'A contract signer name is required',
                    }}
                    error={errors.contractSignerName}
                    defaultValue={customer.contractSignerName}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    fieldName="contractSignerEmail"
                    type="email"
                    label="Contract signer email"
                    register={register}
                    validation={{
                      required: 'A contract signer email is required',
                    }}
                    error={errors.contractSignerEmail}
                    defaultValue={customer.contractSignerEmail}
                  />
                </div>
              </div>
            </div>
            <div className="space-x-4">
              <Button type="submit" color="cyan" className="flex-1">
                Update customer
              </Button>
            </div>
          </form>
        )}
      </Card>
    </>
  )
}

export default CustomerDetailsPage
