import { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetCustomer } from '../../../../hooks/api/queries/useGetCustomer'

import RemoveCustomer from './RemoveCustomer'
import SharedNotesCustomerPage from './SharedNotesCustomer'
import CustomerDetailsPage from './CustomerDetailPage'

import AsideMenu from '../../../../components/AsideMenu'

interface CustomerRoute {
  path: string
  label: string
  element: ReactElement
  separator?: boolean
  disabled?: boolean
}

const Customer = () => {
  const { customerId } = useParams()

  const navigate = useNavigate()

  const { data, isLoading } = useGetCustomer(navigate, customerId)

  if (
    !customerId ||
    typeof parseInt(customerId) !== 'number' ||
    (!data && !isLoading)
  ) {
    return null
  }

  const CUSTOMER_ROUTES: CustomerRoute[] = [
    {
      path: '',
      label: 'Customer details',
      element: <CustomerDetailsPage customer={data} />,
    },

    {
      path: 'shared-notes',
      label: 'Shared notes',
      element: (
        <SharedNotesCustomerPage
          sharedNotes={data?.sharedNotes}
          customerId={data?.id}
        />
      ),
    },
    {
      path: 'remove-customer',
      label: 'Remove Customer',
      element: <RemoveCustomer customerId={parseInt(customerId)} />,
    },
  ]

  return (
    <div>
      <div className="bg-gray-50 border-b border-gray-200">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-end space-x-3">
            <h1 className="text-4xl font-semibold leading-5">
              {data?.companyName || ' '}
            </h1>
          </div>
        </div>
      </div>

      {CUSTOMER_ROUTES.length > 0 && (
        <AsideMenu
          menuTitle={`${data?.companyName} Settings`}
          routes={CUSTOMER_ROUTES}
          indexRoute={`/customers/${customerId}`}
        />
      )}
    </div>
  )
}

export default Customer
