import { Grid } from 'react-loader-spinner'

const Loading = () => {
  return (
    <div className="text-center mx-auto py-6">
      <Grid color="#f65a8e" height={40} width={40} />
    </div>
  )
}

export default Loading
