import React from 'react'
import { useForm } from 'react-hook-form'

import usePatchCustomerSharedNotes from '../../../../hooks/api/mutations/usePatchCustomerSharedNotes'

import Card from '../../../../components/Card'
import FormField from '../../../../components/FormField'
import Button from '../../../../components/Button'

interface SharedNotesCustomerProps {
  sharedNotes?: string
  customerId?: number
}

const SharedNotesCustomerPage: React.FC<SharedNotesCustomerProps> = ({
  sharedNotes,
  customerId,
}) => {
  const mutateNote = usePatchCustomerSharedNotes(customerId)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  return (
    <>
      <Card title="Shared notes">
        {sharedNotes !== undefined && (
          <form onSubmit={handleSubmit((data) => mutateNote.mutateAsync(data))}>
            <div className="mb-6">
              <FormField
                fieldName="sharedNotes"
                type="textarea"
                register={register}
                validation={{ required: 'A note is required' }}
                error={errors.sharedNotes}
                defaultValue={sharedNotes}
              />
            </div>
            <div>
              <Button type="submit" color="cyan" className="flex-1">
                update
              </Button>
            </div>
          </form>
        )}
      </Card>
    </>
  )
}

export default SharedNotesCustomerPage
