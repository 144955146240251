import React from 'react'
import { useForm } from 'react-hook-form'

import usePatchPartner from '../../../hooks/api/mutations/usePatchPartner'

import Card from '../../../components/Card'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'

import { Partner } from '../../../types'

interface partnerDetailsProps {
  partner: Partner
}

const PartnerDetails: React.FC<partnerDetailsProps> = ({ partner }) => {
  const patchPartner = usePatchPartner(partner?.id)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  return (
    <Card title="Your data">
      {partner && (
        <form onSubmit={handleSubmit((data) => patchPartner.mutateAsync(data))}>
          <div className="mb-6">
            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.companyName}
                  fieldName="companyName"
                  type="text"
                  label="Company name"
                  register={register}
                  validation={{ required: 'A company name is required' }}
                  error={errors.companyName}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.technicalContactName}
                  fieldName="technicalContactName"
                  type="text"
                  label="Technical contact name"
                  register={register}
                  validation={{
                    required: 'A technical contact name is required',
                  }}
                  error={errors.technicalContactName}
                />
              </div>
            </div>
            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.address}
                  fieldName="address"
                  type="text"
                  label="Address"
                  register={register}
                  validation={{ required: 'An address is required' }}
                  error={errors.address}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.city}
                  fieldName="city"
                  type="text"
                  label="City"
                  register={register}
                  validation={{ required: 'A city is required' }}
                  error={errors.city}
                />
              </div>
            </div>
            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.province}
                  fieldName="province"
                  type="text"
                  label="Province"
                  register={register}
                  validation={{ required: 'A province is required' }}
                  error={errors.province}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.country}
                  fieldName="country"
                  type="text"
                  label="Country"
                  register={register}
                  validation={{ required: 'A country is required' }}
                  error={errors.country}
                />
              </div>
            </div>
            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.zipCode}
                  fieldName="zipCode"
                  type="text"
                  label="Zip code"
                  register={register}
                  validation={{ required: 'A zip code is required' }}
                  error={errors.zipCode}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.phone}
                  fieldName="phone"
                  type="text"
                  label="Phone number"
                  register={register}
                  validation={{ required: 'A phone number is required' }}
                  error={errors.phone}
                />
              </div>
            </div>

            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.contractSignerEmail}
                  fieldName="contractSignerEmail"
                  type="email"
                  label="Contract signer email"
                  register={register}
                  validation={{
                    required: 'A contract signer email is required',
                  }}
                  error={errors.contractSignerEmail}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.contractSignerName}
                  fieldName="contractSignerName"
                  type="text"
                  label="Contract signer name"
                  register={register}
                  validation={{
                    required: 'A contract signer name is required',
                  }}
                  error={errors.contractSignerName}
                />
              </div>
            </div>

            <div className="flex gap-x-4 mb-6">
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.fiscalCode}
                  fieldName="fiscalCode"
                  type="text"
                  label="Fiscal code"
                  register={register}
                  validation={{ required: 'A fiscal code is required' }}
                  error={errors.fiscalCode}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  defaultValue={partner?.vatId}
                  fieldName="vatId"
                  type="text"
                  label="VAT ID"
                  register={register}
                  validation={{ required: 'A VAT ID is required' }}
                  error={errors.vatId}
                />
              </div>
            </div>
          </div>
          <div className="space-x-4">
            <Button
              type="submit"
              color="cyan"
              loading={patchPartner.isLoading}
              className="flex-1"
            >
              Update
            </Button>
          </div>
        </form>
      )}
    </Card>
  )
}

export default PartnerDetails
