import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

import { useGetAllApps } from '../../../hooks/api/queries/useGetAllApps'
import { useGetAllCustomers } from '../../../hooks/api/queries/useGetAllCustomers'
import useAddApp from '../../../hooks/api/mutations/useAddApp'

import { FiPlusCircle } from 'react-icons/fi'

import Loading from '../../../components/Loading'
import CustomerApp from '../../../components/CustomerApp'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'

import noApps from '../../../images/no_app.svg'

import { modalCustomStyles } from '../../../styles/modalStyles'

import { Tooltip } from 'react-tooltip'

import { App, Customer } from '../../../types'
import { toast } from 'react-toastify'

const Apps = () => {
  const { customerId } = useParams()

  const {
    handleSubmit,
    register,
    resetField,
    formState: { errors },
  } = useForm<any>()

  const [addNewApp, setAddNewApp] = useState<boolean>(false)

  const [customersFilter, setCustomersFilter] = useState<string>(
    customerId !== undefined
      ? customerId.match(/^-?\d+$/) !== null
        ? customerId
        : ''
      : ''
  )

  const location = useLocation()
  const navigate = useNavigate()

  const openAddAppModal = () => {
    setAddNewApp(true)
    resetField('customerId')
  }
  const closeAddAppModal = () => {
    setAddNewApp(false)
  }

  const {
    data: apps,
    isFetching,
    isLoading,
  } = useGetAllApps(navigate, customersFilter)
  const { data: customers } = useGetAllCustomers()
  const addApp = useAddApp()

  const customerOptions = [{ label: 'Select a customer', value: '' }]

  customers?.map((customer: Customer) => {
    customerOptions.push({
      label: customer.companyName,
      value: customer.id.toString(),
    })
  })
  useEffect(() => {
    if (
      location.pathname.substring(6, location.pathname.length) !== '' &&
      customers
    ) {
      if (
        location.pathname
          .substring(6, location.pathname.length)
          .match(/^-?\d+$/) === null
      ) {
        setCustomersFilter('')
      }

      const checkValidSlug = customerOptions.filter((option) => {
        return (
          option.value ===
          location.pathname.substring(6, location.pathname.length)
        )
      })

      if (checkValidSlug.length === 0) {
        setCustomersFilter('')
        toast.error('Not valid customer', {
          position: 'bottom-right',
          autoClose: 5000,
        })
      } else {
        setCustomersFilter(checkValidSlug[0].value)
      }
      navigate('/apps')
    }
  }, [customersFilter, addNewApp, customers])

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white">
        <h1 className="text-3xl font-semibold leading-tight mb-6">
          {"Customers' Apps"}
          {isFetching ? ' ...' : null}
        </h1>
        <div className="md:flex justify-between items-end">
          <div className="max-w-lg basis-[300px]">
            <select
              onChange={(e) => setCustomersFilter(e.target.value)}
              value={customersFilter}
              className="form-select bg-gray-50 focus:bg-white w-full block mt-1 rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            >
              {customerOptions?.map((o: { label: string; value: string }) => {
                return (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                )
              })}
            </select>
          </div>
          {apps && apps.length > 0 && (
            <Button
              color="green"
              className="flex items-center pl-3 relative"
              onClick={openAddAppModal}
            >
              <FiPlusCircle className={`mr-2 text-lime-200`} />
              Add new App
            </Button>
          )}
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          apps &&
          apps?.length === 0 && (
            <>
              <div className="flex flex-wrap sm:flex-no-wrap max-w-sm mx-auto sm:max-w-full sm:mx-0 items-center justify-center sm:justify-between py-12">
                <img
                  src={noApps}
                  alt="noApps"
                  className="sm:w-1/3 w-full mb-12 sm:mb-0"
                />
                <div className="sm:w-2/3 pl-20 w-full text-center sm:text-left">
                  <div className="text-xl mb-12 text-gray-600">
                    <p className="mb-1">There are no apps, yet...</p>
                    <p className="font-bold">But you're just a click away!</p>
                  </div>
                  <div className="flex justify-center sm:justify-start">
                    <Button
                      color="cyan"
                      className="flex items-center py-3 px-6 text-lg"
                      onClick={openAddAppModal}
                    >
                      <FiPlusCircle className="mr-2 text-cyan-200" />
                      Add an app now
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
      {apps && apps.length > 0 && (
        <div
          className="bg-gray-50 border-t border-gray-200 h-full"
          style={{ minHeight: 400 }}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="flex flex-wrap -mx-5">
              {apps.map((app: App) => (
                <div
                  key={`${app.id}_${app.env}`}
                  className="my-3 px-5 w-full md:w-1/2"
                >
                  <NavLink
                    to={app.hidden ? '' : `/app/${app.customer.id}/${app.id}`}
                  >
                    {!app.hidden && <CustomerApp app={app} />}
                    {app.hidden && (
                      <>
                        <div
                          data-tooltip-id="appHidden"
                          data-tooltip-content="App verification in pending..."
                          data-tooltip-place="bottom"
                          data-tooltip-float={true}
                        >
                          <CustomerApp app={app} />
                        </div>
                        <Tooltip id="appHidden" />
                      </>
                    )}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={!!addNewApp}
        onRequestClose={closeAddAppModal}
        ariaHideApp={false}
        style={modalCustomStyles}
      >
        <div className="px-12 py-8 max-w-xl">
          <h2 className="text-xl font-semibold text-center mb-3">Add App</h2>

          <div className="mb-5 text-sm text-gray-600">
            Add an existing React Bricks app.
          </div>

          <form
            onSubmit={handleSubmit((data) => {
              addApp.mutateAsync({ ...data }).then(closeAddAppModal)
            })}
          >
            <div className="mb-6">
              <div className="mb-6">
                <FormField
                  fieldName="appId"
                  type="text"
                  label="AppId"
                  register={register}
                  validation={{
                    required: 'An appId is required',
                    maxLength: {
                      value: 36,
                      message: 'AppId must be equal to 36 characters',
                    },
                    minLength: {
                      value: 36,
                      message: 'AppId must be equal to 36 characters',
                    },
                  }}
                  error={errors?.appId}
                />
              </div>

              <FormField
                fieldName="customerId"
                type="select"
                label="Customer"
                register={register}
                defaultValue={customersFilter}
                validation={{
                  required: 'A customer is required',
                  pattern: { value: /^.+$/, message: 'Select a customer' },
                }}
                error={errors?.customerId}
                options={customerOptions}
              />
            </div>
            <div className="flex items-center space-x-4">
              <Button
                color="gray"
                onClick={closeAddAppModal}
                className="flex-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="cyan"
                loading={addApp?.isLoading}
                className="flex-1"
              >
                Add app
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default Apps
