import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'

const logout = async () => {
  return await axios.get(`/session/signout`)
}

const useLogout = () => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return logout()
    },
    {
      onSuccess: (data) => {
        queryClient.clear()
      },
      onError: () => {},
    }
  )
}

export default useLogout
