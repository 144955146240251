import axios from 'axios'

import { useQuery } from 'react-query'

import { Customer } from '../../../types'
import { NavigateFunction } from 'react-router-dom'

import { toast } from 'react-toastify'

const fetchCustomer = async (
  navigate: NavigateFunction,
  customerId?: string
) => {
  if (customerId && customerId.match(/^-?\d+$/) === null) {
    navigate('/customers')
    toast.error('Not valid customer', {
      position: 'bottom-right',
      autoClose: 5000,
    })
    return undefined
  }
  const { data } = await axios.get<Customer>(`customers/${customerId}`)

  return data
}

export const useGetCustomer = (
  navigate: NavigateFunction,
  customerId?: string
) => {
  return useQuery(['customer', customerId], () =>
    fetchCustomer(navigate, customerId)
  )
}
