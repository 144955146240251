import axios from 'axios'

import { useQuery } from 'react-query'
import { App } from '../../../types'

import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'

const fetchApps = async (navigate: NavigateFunction, id?: string) => {
  if (id && id.match(/^-?\d+$/) === null) {
    navigate('/apps')
    toast.error('Not valid customer', {
      position: 'bottom-right',
      autoClose: 5000,
    })
    return undefined
  }
  const { data: result } = await axios.get<App[]>(
    `customers/apps${id ? `/${id}` : ''}`
  )

  return result
}

export const useGetAllApps = (navigate: NavigateFunction, id?: string) =>
  useQuery(['apps', id], () => fetchApps(navigate, id))
